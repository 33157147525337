











































import fb from 'firebase/app';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ResearchModule } from '../../store/modules/research-module';
import { ResultModule } from '../../store/modules/result-module';
import DashboardItem from './dashboard-item.vue';

@Component({
  components: { DashboardItem },
})
export default class DashboardProgress extends Vue {
  @Prop({ type: Boolean, default: false })
  public blur!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  public data!: { label: string; type: string };

  @Prop({ type: Boolean, default: false })
  public edit!: boolean;

  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: [Object, String], required: true })
  public position!: { x: number; y: number } | 'auto';

  @Prop({ type: Object, required: true })
  public size!: { width: number; height: number };

  public readonly options = ['duration', 'response'];
  private research = getModule(ResearchModule);
  private result = getModule(ResultModule);

  public get label() {
    return this.data.type !== 'response'
      ? this.data.label
      : `${this.data.label} ${this.percentage}%`;
  }

  public get max() {
    if (!this.research.current) return 0;

    switch (this.data.type) {
      case 'duration': {
        const { start, end } = this.research.current.date;
        if (!start || !end) return 0;
        const seconds = end.seconds - start.seconds;
        return Math.round(seconds / 60 / 60 / 24);
      }
      case 'response':
        return this.respondents.length;
      default:
        return 100;
    }
  }

  public get percentage() {
    const percentage = (this.value * 100) / this.max;
    return isNaN(percentage) ? 0 : Math.round(percentage);
  }

  public get respondents() {
    if (!this.research.current) return [];

    const ids = this.result.respondents;
    const rid = this.research.current.id;

    return this.result.filters.length
      ? this.research.respondents.filter(r => ids.indexOf(r.id) >= 0)
      : this.research.respondents.filter(r => r.rid === rid);
  }

  public get value() {
    if (!this.research.current) return 0;

    switch (this.data.type) {
      case 'duration': {
        const today = fb.firestore.Timestamp.fromDate(new Date());
        const { start } = this.research.current.date;
        if (!start) return 0;

        const seconds = today.seconds - start.seconds;
        const days = Math.floor(seconds / 60 / 60 / 24) + 1;
        return days > this.max ? this.max : days;
      }
      case 'response':
        return this.respondents.filter(
          r => r.status === 'done' || r.progress >= 100
        ).length;
      default:
        return Math.floor(Math.random() * this.max);
    }
  }

  public getUnit(type: string) {
    switch (type) {
      case 'duration':
        return this.$t('days');
      case 'response':
        return this.$t('respondents');
      default:
        return '';
    }
  }

  public update(key: string, value: string) {
    this.$emit('input', { ...this.data, [key]: value });
  }
}
