


















import { DashboardResult } from '@app/models';
import { ChartData, ChartOptions } from 'chart.js';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ChartType } from '../../models/chart-type';
import { ModuleModule } from '../../store/modules/module-module';
import { ResultModule } from '../../store/modules/result-module';
import { ChartUtil } from '../../utils/chart';
import DashboardQuestion from './dashboard-question.vue';

@Component({
  components: { DashboardQuestion },
})
export default class DashboardGrade extends Vue {
  @Prop({ type: Boolean, default: false })
  public blur!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  public data!: { label: string; module: string; question: string };

  @Prop({ type: Boolean, default: false })
  public edit!: boolean;

  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: [Object, String], required: true })
  public position!: { x: number; y: number } | 'auto';

  @Prop({ type: Object, required: true })
  public size!: { width: number; height: number };

  @Prop({ type: String, required: true })
  public target!: string;

  public module = getModule(ModuleModule);
  public result = getModule(ResultModule);

  public get colors() {
    const style = getComputedStyle(this.$root.$el);
    const e3 = style.getPropertyValue('--bce-c300-error');
    const e4 = style.getPropertyValue('--bce-c400-error');
    const e5 = style.getPropertyValue('--bce-c500-error');
    const e6 = style.getPropertyValue('--bce-c600-error');
    const e7 = style.getPropertyValue('--bce-c700-error');
    const s3 = style.getPropertyValue('--bce-c300-success');
    const s4 = style.getPropertyValue('--bce-c400-success');
    const s5 = style.getPropertyValue('--bce-c500-success');
    const s6 = style.getPropertyValue('--bce-c600-success');
    const s7 = style.getPropertyValue('--bce-c700-success');
    return [e7, e6, e5, e4, e3, s3, s4, s5, s6, s7];
  }

  public get dashboardResult() {
    return this.result.dashboard.find(r => r.elementId === this.data.question);
  }

  public getChartData(type: string): ChartData<ChartType> | undefined {
    const result = this.dashboardResult;
    if (!result) return undefined;

    return type === 'bar' ? this.toBar(result) : this.toDoughnut(result);
  }

  public getChartOptions(type: string): ChartOptions<ChartType> {
    const result = this.dashboardResult;
    if (!result) return {};

    return type === 'bar'
      ? { scales: ChartUtil.chartAxes(result) }
      : {
          plugins: {
            datalabels: { display: false },
            legend: {
              display: true,
              labels: {
                boxWidth: 0,
                color: 'black',
                font: { size: 20 },
                padding: 20,
              },
              position: 'bottom',
            },
            tooltip: { enabled: false },
          },
        };
  }

  private getAverage(result: DashboardResult) {
    const dataset = result.dataset as number[];
    const total = dataset.reduce((acc, val, i) => acc + (i + 1) * val, 0);
    const answers = dataset.reduce((acc, val) => acc + val, 0);
    return total / answers;
  }

  private toBar(result: DashboardResult): ChartData<'bar'> {
    const average = this.getAverage(result);

    return {
      datasets: [
        {
          data: ChartUtil.normalize(result.dataset as number[]),
          label: +average.toFixed(1) + '',
          backgroundColor: this.colors,
        },
      ],
      labels: result.labels,
    };
  }

  private toDoughnut(result: DashboardResult): ChartData<'doughnut'> {
    const average = this.getAverage(result);

    const pos = this.colors[6];
    const neg = this.colors[3];

    return {
      datasets: [
        {
          data: ChartUtil.normalize([average, 10 - average]),
          backgroundColor: [pos, neg],
        },
      ],
      labels: [+average.toFixed(1)],
    };
  }
}
