






































































































import { ModuleQuestion } from '@app/models';
import { ChartData, ChartOptions } from 'chart.js';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ChartType } from '../../models/chart-type';
import { ModuleModule } from '../../store/modules/module-module';
import { ResearchModule } from '../../store/modules/research-module';
import { ResultModule } from '../../store/modules/result-module';
import { ChartUtil } from '../../utils/chart';
import ChartBar from '../charts/chart-bar.vue';
import ChartDoughnut from '../charts/chart-doughnut.vue';
import DashboardItem from './dashboard-item.vue';

@Component({
  components: { ChartBar, ChartDoughnut, DashboardItem },
})
export default class DashboardQuestion extends Vue {
  @Prop({ type: Boolean, default: false })
  public blur!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  public data!: {
    free?: boolean;
    label: string;
    module: string;
    question: string;
    type: ChartType;
  };

  @Prop({ type: Boolean, default: false })
  public edit!: boolean;

  @Prop({ type: Function })
  public getChartData?: (type: string) => ChartData<ChartType>;

  @Prop({ type: Function })
  public getChartOptions?: (type: string) => ChartOptions<ChartType>;

  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: Boolean, default: false })
  public onlyBar!: boolean;

  @Prop({ type: [Object, String], required: true })
  public position!: { x: number; y: number } | 'auto';

  @Prop({ type: Object, required: true })
  public size!: { width: number; height: number };

  @Prop({ type: String, required: true })
  public target!: string;

  public research = getModule(ResearchModule);
  public result = getModule(ResultModule);
  public module = getModule(ModuleModule);
  public customType: ChartType | '' = '';

  public get active() {
    if (this.$customerNameShort !== 'fb') return true;
    return (
      (this.research.current && this.research.current.company === '<demo>') ||
      this.$company.license !== 'free' ||
      this.data.free
    );
  }

  public get chart(): ChartType {
    return this.forceBar
      ? 'bar'
      : this.customType || this.data.type || 'doughnut';
  }

  public get chartData() {
    if (this.getChartData) {
      const data = this.getChartData(this.chart);
      if (data) return data;
    }

    const result = this.dashboardResult;
    return result ? ChartUtil.toChartData(this.chart, result) : this.sampleData;
  }

  public get chartOptions() {
    if (this.getChartOptions) {
      const options = this.getChartOptions(this.chart);
      if (options) return options;
    }

    const result = this.dashboardResult;
    return result ? ChartUtil.toChartOptions(this.chart, result) : {};
  }

  public get dashboardResult() {
    return this.result.dashboard.find(
      r =>
        r.elementId === this.data.question ||
        r.elementId === this.data.question + 'E'
    );
  }

  public get forceBar() {
    if (this.onlyBar) return true;

    const question = this.questions.find(q => q.id === this.data.question);
    return !!(!this.getChartData && question && question.type === 'matrix');
  }

  public get modules() {
    return this.module.all
      .filter(m => m.category.split('+').some(c => c.startsWith(this.target)))
      .sort((a, b) => a.metadata.order - b.metadata.order);
  }

  public get questions(): any[] {
    const module = this.modules.find(m => m.id === this.data.module);
    if (!module) return [];

    if (module.metadata.fsq) {
      const fsq =
        this.research.current &&
        this.research.current.questions
          .filter(q => 'question' in q)
          .map<ModuleQuestion>(q => ({
            id: q.id,
            label: (q as any).question,
            metadata: q.metadata,
            type: q.type,
          }));

      return fsq || [];
    }

    return Object.values(module.metadata.question);
  }

  public get sampleData() {
    return {
      datasets: [{ data: this.chart === 'doughnut' ? [1] : [] }],
      labels: [],
    };
  }

  @Watch('edit')
  public watchEdit() {
    if (this.edit) this.customType = '';
  }

  public open() {
    if (!this.active) return;

    this.$router.push({
      name: 'dashboard-result',
      params: { id: this.$route.params.id, eid: this.data.question },
    });
  }

  public switchChart(event: Event, type: ChartType) {
    event.stopPropagation();
    this.customType = type;
  }

  public update(key: string, value: string) {
    this.$emit('input', {
      ...this.data,
      question: key === 'module' ? '' : this.data.question || '',
      [key]: value,
    });
  }
}
