


































































import 'gridstack/dist/gridstack.css';
import 'gridstack/dist/gridstack-extra.css';

import { DashboardItem } from '@app/models';
import { GridStack, GridStackNode, GridStackOptions } from 'gridstack';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import DashboardEnps from './dashboard-enps.vue';
import DashboardGrade from './dashboard-grade.vue';
import DashboardLabel from './dashboard-label.vue';
import DashboardPriorities from './dashboard-priorities.vue';
import DashboardProgress from './dashboard-progress.vue';
import DashboardQuestion from './dashboard-question.vue';

@Component({
  components: {
    DashboardEnps,
    DashboardGrade,
    DashboardLabel,
    DashboardPriorities,
    DashboardProgress,
    DashboardQuestion,
  },
})
export default class DashboardGrid extends Vue {
  @Prop({ type: Boolean, default: true })
  public actions!: boolean;

  @Prop({ type: Boolean, default: false })
  public blur!: boolean;

  @Prop({ type: Boolean, default: false })
  public reset!: boolean;

  @Prop({ type: String, required: true })
  public target!: string;

  @Prop({ type: Array, required: true })
  public value!: DashboardItem[];

  public edit = false;
  public skipEmit = false;
  private _grid?: GridStack;

  public get options() {
    return this.$FEATURES.dashboard_item;
  }

  async mounted() {
    const options: GridStackOptions = {
      acceptWidgets: true,
      animate: false,
      auto: false,
      cellHeight: 50,
      disableOneColumnMode: false,
      draggable: { cancel: 'bce-input, bce-select' } as any,
      dragIn: '.grid-stack-item',
      dragInOptions: {
        revert: 'invalid',
        scroll: false,
        appendTo: 'body',
        helper: 'clone',
      },
      float: false,
      margin: 0,
      minRow: 1,
      staticGrid: !this.edit,
    };

    this._grid = GridStack.init(options);
    this._grid.on('added', this.onGridChange as any);
    this._grid.on('change', this.onGridChange as any);
    this._grid.on('removed', this.onGridChange as any);
    this.watchValue(this.value, []);
  }

  @Watch('edit')
  public watchEdit() {
    if (this._grid) this._grid.setStatic(!this.edit);
  }

  @Watch('value')
  public async watchValue(next: DashboardItem[], prev: DashboardItem[]) {
    if (!this._grid) return;

    // Wait for vue to render the items
    await this.$nextTick();

    // Make widget once per item
    const items = this._grid.getGridItems();
    for (const item of items) {
      const value = this.value.find(v => v.id === item.id);
      if (value && !item.gridstackNode) {
        this.skipEmit = value.position !== 'auto';
        this._grid.makeWidget(item);
        this.skipEmit = false;
      }
    }
  }

  public async addItem(type: string) {
    const item: DashboardItem = {
      data: {},
      id: this.$firebase.generateId(),
      position: 'auto',
      size: { width: 0, height: 0 },
      type,
    };
    this.$emit('input', [...this.value, item]);

    await this.$nextTick();
    if (this._grid) this._grid.compact();
  }

  public deleteItem(index: number) {
    const copy = [
      ...this.value.slice(0, index),
      ...this.value.slice(index + 1),
    ];
    this.$emit('input', copy);
  }

  public updateItem(index: number, data: any) {
    const copy = [...this.value];
    copy[index] = { ...this.value[index], data };
    this.$emit('input', copy);
  }

  private onGridChange(event: Event, value: GridStackNode[]) {
    const copy = [...this.value];

    for (const item of value) {
      const { id } = item;
      const width = item.width!;
      const height = item.height!;
      const x = item.x!;
      const y = item.y!;

      const index = this.value.findIndex(i => i.id === id);
      copy[index] = {
        ...this.value[index],
        position: { x, y },
        size: { width, height },
      };
    }

    if (!this.skipEmit) this.$emit('input', copy);
  }
}
