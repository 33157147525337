




















import { DashboardResultMatrix } from '@app/models';
import { ChartData, ChartOptions } from 'chart.js';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ChartType } from '../../models/chart-type';
import { ModuleModule } from '../../store/modules/module-module';
import { ResultModule } from '../../store/modules/result-module';
import { ChartUtil } from '../../utils/chart';
import DashboardQuestion from './dashboard-question.vue';

@Component({
  components: { DashboardQuestion },
})
export default class DashboardPriorities extends Vue {
  @Prop({ type: Boolean, default: false })
  public blur!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  public data!: { label: string };

  @Prop({ type: Boolean, default: false })
  public edit!: boolean;

  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: [Object, String], required: true })
  public position!: { x: number; y: number } | 'auto';

  @Prop({ type: Object, required: true })
  public size!: { width: number; height: number };

  @Prop({ type: String, required: true })
  public target!: string;

  public module = getModule(ModuleModule);
  public result = getModule(ResultModule);

  public getChartData(type: string): ChartData<ChartType> | undefined {
    const impQ = this.module.findDashboard('subject-importance');
    const satQ = this.module.findDashboard('subject-satisfaction');
    if (!impQ || !satQ) return undefined;

    const imp = this.result.dashboard.find(r => r.elementId === impQ.id);
    const sat = this.result.dashboard.find(r => r.elementId === satQ.id);
    if (!imp || !sat) return undefined;

    type DRM = DashboardResultMatrix;
    return type === 'bar'
      ? this.toBar(imp as DRM, sat as DRM)
      : this.toDoughnut(imp as DRM, sat as DRM);
  }

  public getChartOptions(type: string): ChartOptions<ChartType> | undefined {
    return type === 'bar'
      ? {
          plugins: { legend: { display: true } },
          scales: { yAxes: { beginAtZero: false, max: 1, min: -1 } },
        }
      : undefined;
  }

  private toBar(
    imp: DashboardResultMatrix,
    sat: DashboardResultMatrix
  ): ChartData<'bar'> {
    const [c1, c2, c3] = ChartUtil.color(3);

    const impD = this.parseDataset(imp.dataset as any);
    const satD = this.parseDataset(sat.dataset as any);
    const avgD = impD.map((v, i) => v - satD[i]);

    const max =
      (imp.dataset as any)[0][0] +
      (imp.dataset as any)[0][1] +
      (imp.dataset as any)[0][2] +
      (imp.dataset as any)[0][3] +
      (imp.dataset as any)[0][4];

    const sorting = avgD
      .map((v, i) => ({ v, i }))
      .sort((a, b) => b.v - a.v)
      .map(s => s.i);
    const sort = (arr: any[]) => sorting.map(i => arr[i]);

    return {
      datasets: [
        this.toDataset(sort(impD), max, c1, 'Belang'),
        this.toDataset(sort(satD), max, c2, 'Tevredenheid'),
        this.toDataset(sort(avgD), max, c3, 'Belang -/- Tevredenheid'),
      ],
      labels: sort(imp.labels),
    };
  }

  private toDoughnut(
    imp: DashboardResultMatrix,
    sat: DashboardResultMatrix
  ): ChartData<'doughnut'> {
    const impD = this.parseDataset(imp.dataset);
    const satD = this.parseDataset(sat.dataset);
    const avgD = satD.map((v, i) => v - impD[i]);
    const indexes = ChartUtil.findIndexes(avgD, 3, 'min');

    return {
      datasets: [
        {
          data: ChartUtil.normalize(indexes.map(i => avgD[i])),
          backgroundColor: ChartUtil.color(3),
        },
      ],
      labels: indexes.map(i => imp.labels[i]),
    };
  }

  private parseDataset(dataset: { [key: number]: number[] }): number[] {
    return Object.keys(dataset).reduce((acc, _, i) => {
      acc[i] = dataset[i][3] + dataset[i][4];
      return acc;
    }, [] as number[]);
  }

  private toDataset(data: number[], max: number, color: string, label: string) {
    return {
      backgroundColor: color,
      data: data.map(v => v / max),
      label,
      minBarLength: ChartUtil.minBarLength,
    };
  }
}
