






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import DashboardItem from './dashboard-item.vue';

@Component({
  components: { DashboardItem },
})
export default class DashboardLabel extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public data!: { text: string };

  @Prop({ type: Boolean, default: false })
  public edit!: boolean;

  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: [Object, String], required: true })
  public position!: { x: number; y: number } | 'auto';

  @Prop({ type: Object, required: true })
  public size!: { width: number; height: number };
}
