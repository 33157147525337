



















import { DashboardResult } from '@app/models';
import { ChartData, ChartOptions } from 'chart.js';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ChartType } from '../../models/chart-type';
import { ModuleModule } from '../../store/modules/module-module';
import { ResultModule } from '../../store/modules/result-module';
import { ChartUtil } from '../../utils/chart';
import DashboardQuestion from './dashboard-question.vue';

@Component({
  components: { DashboardQuestion },
})
export default class DashboardENPS extends Vue {
  @Prop({ type: Boolean, default: false })
  public blur!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  public data!: { label: string };

  @Prop({ type: Boolean, default: false })
  public edit!: boolean;

  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: [Object, String], required: true })
  public position!: { x: number; y: number } | 'auto';

  @Prop({ type: Object, required: true })
  public size!: { width: number; height: number };

  @Prop({ type: String, required: true })
  public target!: string;

  public module = getModule(ModuleModule);
  public result = getModule(ResultModule);

  public get colors() {
    const style = getComputedStyle(this.$root.$el);
    const e3 = style.getPropertyValue('--bce-c300-error');
    const e4 = style.getPropertyValue('--bce-c400-error');
    const e5 = style.getPropertyValue('--bce-c500-error');
    const e6 = style.getPropertyValue('--bce-c600-error');
    const e7 = style.getPropertyValue('--bce-c700-error');
    const d4 = style.getPropertyValue('--bce-c400-dark');
    const d5 = style.getPropertyValue('--bce-c500-dark');
    const s4 = style.getPropertyValue('--bce-c400-success');
    const s6 = style.getPropertyValue('--bce-c600-success');
    return [e7, e7, e6, e5, e5, e4, e3, d4, d5, s4, s6];
  }

  public get dashboardResult() {
    const question = this.module.findDashboard('enps');
    return (
      question && this.result.dashboard.find(r => r.elementId === question.id)
    );
  }

  public getChartData(type: ChartType): ChartData<ChartType> | undefined {
    const result = this.dashboardResult;
    if (!result) return undefined;

    return type === 'bar' ? this.toBar(result) : this.toDoughnut(result);
  }

  public getChartOptions(type: string): ChartOptions<ChartType> {
    const result = this.dashboardResult;
    if (!result) return {};

    return type === 'bar'
      ? { scales: ChartUtil.chartAxes(result) }
      : {
          cutout: 70,
          plugins: {
            legend: {
              display: true,
              labels: {
                boxWidth: 0,
                color: 'black',
                font: { size: 20 },
                padding: 20,
              },
              position: 'bottom',
            },
            datalabels: { color: '#fff' },
            tooltip: { enabled: false },
          },
        };
  }

  public getEnps(result: DashboardResult) {
    const data = result.dataset as number[];

    const combine = (val: number[]) => val.reduce((acc, cur) => acc + cur, 0);
    const percentage = (val: number, total: number) => (val * 100) / total;

    const total = combine(data);
    const detractors = percentage(combine(data.slice(0, 7)), total);
    const passives = percentage(combine(data.slice(7, 9)), total);
    const promoters = percentage(combine(data.slice(9, 11)), total);
    const enps = promoters - detractors;

    return { detractors, passives, promoters, value: enps };
  }

  private toBar(result: DashboardResult): ChartData<'bar'> {
    const enps = this.getEnps(result);

    return {
      datasets: [
        {
          backgroundColor: this.colors,
          data: ChartUtil.normalize(result.dataset as number[]),
          label: enps.value.toFixed(1),
        },
      ],
      labels: result.labels,
    };
  }

  private toDoughnut(result: DashboardResult): ChartData<'doughnut'> {
    const enps = this.getEnps(result);
    const data = [enps.promoters, enps.passives, enps.detractors];

    return {
      datasets: [
        {
          backgroundColor: [this.colors[9], this.colors[7], this.colors[4]],
          data: ChartUtil.normalize(data),
        },
      ],
      labels: [enps.value.toFixed(1)],
    };
  }
}
