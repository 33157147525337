



























import { GridStack } from 'gridstack';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DashboardItem extends Vue {
  @Prop({ type: Boolean, required: true })
  public edit!: boolean;

  @Prop({ type: Number, required: true })
  public height!: number;

  @Prop({ type: String, required: true })
  public id!: string;

  @Prop({ type: Number, default: 8 })
  public maxHeight!: number;

  @Prop({ type: Number, default: 12 })
  public maxWidth!: number;

  @Prop({ type: Number, default: 1 })
  public minHeight!: number;

  @Prop({ type: Number, default: 4 })
  public minWidth!: number;

  @Prop({ type: [Object, String], required: true })
  public position!: { x: number; y: number } | 'auto';

  @Prop({ type: Number, required: true })
  public width!: number;

  public get x() {
    return typeof this.position === 'string' ? undefined : this.position.x;
  }

  public get y() {
    return typeof this.position === 'string' ? undefined : this.position.y;
  }

  async beforeDestroy() {
    const grid = document.querySelector('.grid-stack');
    const gridstack: GridStack = grid && (grid as any).gridstack;
    if (!grid || !gridstack) return;

    // Note: gridstack expects the parent element to be the container element
    // but at this point Vue already detached this element from the parent (even
    // on beforeDestroy). That's why we re-append the element and tell gridstack
    // to remove it for us.
    grid.append(this.$el);
    gridstack.removeWidget(this.$el as any, true, false);
  }
}
